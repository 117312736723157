<template lang="pug">
v-sheet(width="100%" :class="{'shaky':showError}")
  v-alert.mb-2(v-model="showError" type="error",text,dense,dismissible) Выберите тип оплаты!
  v-slide-group.paymenttypes(active-class="success" :value="value" @change="change" show-arrows)
    template(slot="prev")
      v-btn(Ztext large depressed color="grey darken-3"): v-icon(color="primary") mdi-chevron-left
    template(slot="next")
      v-btn(Ztext large depressed color="grey darken-3"): v-icon(color="primary") mdi-chevron-right

    v-slide-item(v-for="(t,k) in typeList" :value="k" :key="t.i" v-slot="{ active, toggle }")
      v-btn.mr-1(large width="160" :class="{'primary--text':!active}" depressed :input-value="active"
        :color="active?'primary':'grey darken-3'" @click="toggle")
        v-avatar.mr-2(v-if="t.icon_img" size="22" :color="t.color" rounded)
          img(:src="t.icon_img" style="width:22px !important")
        v-icon(v-else="t.icon" left) {{t.icon}}
        | {{k}}

      //- v-card.ma-4(:color="active ? undefined : 'grey lighten-1'" height="200" width="100" @click="toggle")
        v-row.fill-height(align="center" justify="center")
          v-scale-transition
            v-icon(v-if="active" color="white" size="48" v-text="'mdi-close-circle-outline'")
//- v-tabs.pt-0(fixed-tabs v-model="type" Zdense grow Zshow-arrows)
  v-tab.pa-0.mr-2(Zactive-class="primary" style="width:160px"): v-btn(block large depressed :color="type==0?'primary':'grey darken-3'"  @click="cash=2800" Zoutlined)
    v-icon(left) mdi-cash
    | Наличные
  v-tab.pa-0.mr-2(style="width:160px"): v-btn(block large :color="type==1?'primary':'grey darken-3'" depressed @click="card=3000" Zoutlined)
    v-icon(left) mdi-credit-card
    |  Карта
  v-tab.pa-0.mr-2(style="width:160px"): v-btn(block large :color="type==2?'red':'grey darken-3'"  depressed @click="card=4000" Zoutlined)
    //- v-icon(left) mdi-card
    v-avatar.mr-2(size="22" color="red" rounded)
      img(src="/logo/kaspi.svg" style="width:16px !important")
    | Kaspi QR
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {},
  props: {
    value: {
      type: String,
      default: undefined,
    },
    autoselect: {
      type: Boolean,
      default: false,
    },
    typeList: {
      type: Object,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showError: false,
  }),
  watch: {
    error() {
      this.showError = true
    },
  },
  computed: {
    ...mapGetters({
      // cart: 'tjtheme/cart',
      // totalSum: 'tjtheme/total',
    }),
  },
  methods: {
    ...mapActions({}),
    change(e) {
      this.showError = false
      this.$emit('input', e)
    },
  },
}
</script>
<style lang="stylus">
.paymenttypes
  .v-slide-group__prev--disabled, .v-slide-group__next--disabled
    display none !important
  .v-slide-group__prev
    margin-left 0px
    flex 0 1 35px
    min-width 35px
  .v-slide-group__next
    margin-right 4px
    flex 0 1 35px
    min-width 35px
  .v-btn:not(.v-btn--round)
    height 36px
    min-width 4px
    padding 0 4px
</style>
